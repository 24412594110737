<script setup lang="ts">
import { OnboardingDisclaimer } from '@/modules/auth/components/onboarding';

useHead({
  title: 'Disclaimer',
});

definePageMeta({
  auth: true,
  layout: 'guest',
  // middleware: ['verify'],
});
</script>
<template>
  <v-container fluid class="d-flex align-center">
    <OnboardingDisclaimer />
  </v-container>
</template>

<style scoped></style>
