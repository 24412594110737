<script setup lang="ts">
import loudspeaker from '@/assets/img/loudspeaker.png';
import { AppButton, AppFormCard, AppTypography } from '@/core/components';
import { client, worker } from '@/core/content/disclaimer';
import { CLIENT } from '@/utils/enums/UserTypes';
import { computed, ref } from 'vue';

const route = useRoute();
const { userType } = route.query;

const content = computed(() => {
  return userType === CLIENT ? client : worker;
});

interface RouteParams {
  name: string;
}

async function handleContinue(): Promise<void> {
  const page = ref<RouteParams>({ name: 'onboarding-id-selector' });
  await navigateTo(page.value);
}
</script>

<template>
  <AppFormCard class="mx-auto disclaimer-card">
    <AppTypography type="large" element="h1" class="text-center">
      {{ userType === 'client' ? "You're your own boss!" : 'Before you start...' }}
    </AppTypography>
    <v-img :src="loudspeaker" class="mx-auto" width="124" height="160" />
    <AppTypography type="body-2" element="div" class="py-6 mx-4 text-justify custom-content">
      <MDC :value="content" />
    </AppTypography>

    <AppButton block color="primary" class="mt-4" rounded="xl" type="button" @click="handleContinue">
      Continue
    </AppButton>
  </AppFormCard>
</template>

<style scoped>
.disclaimer-card {
  max-width: var(--form-max-width);
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
</style>
