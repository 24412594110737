export const client = `
To ensure compliance with SupportApp's safety standards, the following are required to sign-up as a Seeker on SupportApp:

- 2 Forms of Identification for verification through our partner FrankieOne. **Please make sure to input your First and Last Name as indicated on your identity documents.**
`;

export const worker = `
#### You're your own boss! <br>
As an Independent Contractor and to ensure compliance with SupportApp's safety standards, the following documents are required to sign-up as a Worker on SupportApp:

####
#### Compulsory

- 2 Forms of Identification for verification through our partners FrankieOne and Checked Australia. **Please make sure to input your First and Last Name as indicated on your identity documents.**
- You will need to upload a police check that has been issued within the last 90 days, or complete a new police check with our partner for $54.
- A current First Aid Certificate
- A certificate of currency for your Professional Indemnity and Public Liability Insurance
- An ABN
- NDIS Orientation Module Certificates
- Working with Children Check – NSW, SA, VIC, NT, WA ONLY
- Working with Vulnerable People Card – ACT ONLY
- Blue Card - QLD Only

**Optional**
- Any Certificates or Degrees or Diplomas that may be applicable to your role and experience
`;
